<template>
 <div class="card card-custom">
    <!--begin::Header-->
    <div class="card-header py-3">
      <div class="card-title align-items-start flex-column">
        <h3 class="card-label font-weight-bolder text-dark">{{ $t("users.heading.password") }}</h3>
        <span class="text-muted font-weight-bold font-size-sm mt-1">{{ $t("users.subheading.password") }}</span>
      </div>
      <div class="card-toolbar">
        <router-link :to="{ name: 'users'}" class="btn btn-light-primary font-weight-bolder mr-2">
          <i class="ki ki-long-arrow-back icon-xs mr-2"></i>
          {{ $t("users.button.overview") }}
        </router-link>
        <button
          type="submit"
          class="btn btn-success mr-2"
          ref="save_submit"
          @click.prevent="setPassword">
          {{ $t("users.button.save_password") }}
        </button>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Form-->
    <ValidationObserver ref="form">
      <div class="card-body">
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-alert">{{ $t("label.password_actual") }}</labeL>
          <div class="col-lg-9 col-xl-6">
            <ValidationProvider rules="required|min:8" v-slot="{ errors }">
            <input 
              class="form-control form-control-lg form-control-solid"
              name="current_password"
              type="password"
              v-model="currentPassword"
              v-bind:placeholder="$t('placeholder.password_actual')"
            />
            <span class="text-danger">{{ errors[0] }}</span>  
            </ValidationProvider>
          </div>
        </div>
    
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-alert">{{ $t("label.password_new") }}</label>
          <div class="col-lg-9 col-xl-6">
            <ValidationProvider name="password" rules="min:8" v-slot="{ errors }">
            <input
              class="form-control form-control-lg form-control-solid"
              name="password"
              type="password"
              v-model="password"
              v-bind:placeholder="$t('placeholder.password_new')"
            />
            <span class="text-danger">{{ errors[0] }}</span>  
            <span class="form-text text-muted">{{ $t("users.title.password_hint") }}</span>
            </ValidationProvider>
            
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-alert">{{ $t("label.password_repeat") }}</label>
          <div class="col-lg-9 col-xl-6">
            <ValidationProvider rules="min:8|confirmed:password" v-slot="{ errors }">
            <input
              class="form-control form-control-lg form-control-solid"
              name="password_confirmation"
              type="password"
              v-model="passwordConfirmed"
              v-bind:placeholder="$t('placeholder.password_repeat')"
            />
            <span class="text-danger">{{ errors[0] }}</span>  
            </ValidationProvider>
          </div>
        </div>
        <div class="alert alert-custom alert-light-primary fade show mt-10">			
          <div class="alert-text">{{ $t("users.title.password_random") }}</div>
        </div>
      </div>
    </ValidationObserver>
    <!--end::Form-->
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import { checkErrors } from "@/core/utils/api-errors";

export default {
  name: "user_password",
  data() {
    return {
      currentPassword: null,
      password: null,
      passwordConfirmed: null,
    };
  },
  metaInfo () { 
      return { title: this.$t("users.meta.subtitle.password")} 
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.$t("users.meta.title_user"), subtitle: this.$t("users.meta.subtitle.password") }]);
  },
  methods: {
    setPassword() {
      // Loading spinner
      const submitButton = this.$refs["save_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      this.loading = true;
      this.$refs.form.validate().then(success => {
        if (!success) {
          this.loading = false;
          this.$toasted.error("Zkontrolujte zadané údaje.");
          submitButton.classList.remove("spinner","spinner-light","spinner-right");
          return;
        }
        ApiService.apiPost("/users/" + this.$route.params.id + "/set-password", { 
          'user_id': this.$route.params.id,
          'current_password': this.currentPassword,
          'password': this.password,
          'password_confirmation': this.passwordConfirmed
        })
        .catch(error => {
            checkErrors(error);
        })
        .then(() => {
            submitButton.classList.remove("spinner","spinner-light","spinner-right");
        });
      });  
    }
  }
};
</script>
